<template>
	<div id="button-row">
		<router-link class="button is-primary hvr-glow" :to="{name:'getQuote'}">
				<strong>GET A QUOTE</strong>
		</router-link>

		<router-link class="button is-info hvr-glow" :to="{name:'policyWordingsMUL'}">
				<strong>POLICY WORDINGS</strong>
		</router-link>

		<router-link class="button is-info hvr-glow" :to="{name:'compareCoverLevels',query:{type: coverType}}">
				<strong>COMPARE COVER LEVELS</strong>
		</router-link>

		<!--<router-link class="button is-info hvr-glow" :to="{name:'whichArea'}">
				<strong>COUNTRIES COVERED</strong>
		</router-link>-->
	</div>	
</template>

<script>
    export default {
				name: "InfoBoxes",
				props: ['policyType', 'coverType']
    }
</script>

<style scoped>
	.button {
		margin-right: 10px;
	}
	#button-row {
		margin-bottom: 30px;
	}
</style>